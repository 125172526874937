import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"
import { Space, Subhead, Text } from "../components/ui"

interface ContactProps {
  data: {
    homepage: {
      id: string
      title: string
      description: string
      image: { id: string; url: string }
      blocks: sections.HomepageBlock[]
    }
  }
}

export default function Contact(props: ContactProps) {
  const { homepage } = props.data

  return (
    <Layout {...homepage}>
      <Space size={6} />
      <Subhead center>
        For reservations, email villageinnfairhaven@gmail.com
        <p>or call (315) 439-4433</p>
        <Space size={6} />
        <Text variant="kicker">
          Half the cost of the reservation is due upon booking, and is
          non-refundable.
          <p>
            The remainder will be charged 14 days before your arrival, and is
            non-refundable.
          </p>
        </Text>
      </Subhead>
    </Layout>
  )
}

export const query = graphql`
  {
    homepage {
      id
      title
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...HomepageHeroContent
        ...HomepageFeatureListContent
        ...HomepageCtaContent
        ...HomepageLogoListContent
        ...HomepageTestimonialListContent
        ...HomepageBenefitListContent
        ...HomepageStatListContent
        ...HomepageProductListContent
      }
    }
  }
`
